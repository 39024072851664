.billingContainer {
    padding: 24px 24px 24px 0;
}

.title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 24px;
    color: #212529;
}

.paymentMethodContainer {
    height: auto;
    display: flex;
}

.paymentMethodCard {
    border: 2px solid #E3E3E3;
    border-radius: 8px;
    width: 100%;
    padding: 13px 16px;
    margin-bottom: 14px;
    transition: .3s;
    cursor: pointer;
}

.paymentMethodCard:hover {
    border: 2px solid #1C7ED6 !important;
}

.cardHolderNumber {
    margin: 0 0 0 20px;
}

.cardHolderName {
    margin: 0 30px;
}

.cardTitle {
    color: #A6A7AB;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 4px;
}

.cardValue {
    font-size: 16px;
    font-weight: 600;
    color: #373A40;
}

.selectCard {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.selectCard input {
    margin-right: 5px;
}

.selectCard p {
    margin: 0;
    padding: 0;
}

/* tax detail */

.taxDetailContainer {
    padding: 24px;
}

/* .inputContainer:nth-child(2) {
    margin-top: 20px;
} */

.inputContainer {
    margin-bottom: 20px;
}

.inputContainer input {
    height: 40px;
    border: 1px solid #CED4DA;
}

.inputContainer .label {
    font-size: 16px;
    font-weight: 600;
    color: #373A40;
}