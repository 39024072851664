.bg {
    background-color: #212529;
    width: 445px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.signInBackToLanding {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.signInBackToLanding2 p {
    margin: 10px 0;
}

.sideBarFooterLogo {
    display: flex;
    align-items: center;
    margin-top: auto;
    margin-bottom: 50px;
}

.sideBarFooterLogo :nth-child(2) {
    margin-left: 30px;
}

.usVeteran,
.backToLanding,
.signIn {
    font-weight: 400;
    font-size: 16px;
    color: #868E96;
}

.backToLanding:hover,
.signIn:hover {
    cursor: pointer;
    text-decoration: underline;
}