.organization-menu {
    background-color: black;
    color: white
}

.organization-menu .menu-item {
    border-bottom: 1px solid #495057;
}

.organization-menu .menu-item:last-child{
    border-bottom: none;
}