.deleteUser {
    width: 85px !important;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFE3E3;
    color: #C92A2A;
    font-size: 12px;
    font-weight: 400;
    border: none;
    border-radius: 6px;
}

#contained {
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
}

#default {
    background-color: #F8F9FA;
    border: 2px solid #ADB5BD;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #A6A7AB;
}

#defaultDark {
    background-color: #E9ECEF;
    border: 1px solid #ADB5BD;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #343A40;
}

#outline {
    background-color: white;
    border: 2px solid #3354F4;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #3354F4;
}