.card {
    border: 2px solid #E3E3E3;
    padding: 24px;
    border-radius: 8px;
    width: 100%;
    margin: 0 8px;
    border-radius: 16px;
    background-color: white;
}

.title {
    font-size: 24px;
    font-weight: 700;
    color: #212529;
}

.card_title {
    font-size: 16px;
    font-weight: 700;
    color: #23272E;
    margin-bottom: 4px;
}

.card_desc {
    font-size: 14px;
    font-weight: 400;
    color: #909296;
}

.createNewProgramBtn,
.saveProgramBtn {
    font-size: 14px;
    font-weight: 400;
    height: 32px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Program info */

.programInfoContainer {
    width: 494px;
    border-left: 1px solid #DFE0E1;
    border-right: 1px solid #DFE0E1;
    padding: 24px;
}

.inputSection {
    margin-top: 24px;
}

.inputContainer {
    margin-bottom: 20px;
}

.inputContainer input {
    height: 40px;
    border: 2px solid #CED4DA;
}

.inputContainer textarea {
    height: 150px;
    border: 2px solid #CED4DA;
}

.inputContainer .label {
    font-size: 16px;
    font-weight: 600;
    color: #373A40;
}

/* rewards */

.rewardsContainer {
    padding: 24px;
}

/* participants */

.participantsContainer {
    padding: 24px;
}

#deleteProgram {
    background-color: #E7F5FF;
    border: 2px solid #3354F4;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    color: #3354F4;
    margin-left: 10px;
}

#addToSafetyProgram {
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
}

.safetyEmployeeContainer {
    width: 100%;
    border: 2px solid #DEE2E6;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 8px;
    transition: 0.3s;
    cursor: pointer;
}

.safetyEmployeeContainer:hover {
    background-color: #E7F5FF;
}

.nameProfContainer {
    margin-left: 12px;
}

.nameProfContainer :nth-child(1) {
    font-size: 14px;
    font-weight: 600;
    color: #141517;
}

.nameProfContainer :nth-child(2) {
    font-size: 12px;
    font-weight: 400;
    color: #3354F4;
}

/* Award requests */

.input input {
    height: 32px;
    font-size: 14px;
    font-weight: 400;
    color: #343A40;
    border: 2px solid #CED4DA;
    border-radius: 6px;
    padding: 0 30px;
}

.input input::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #343A40;
}