.table-wrapper {
    border: 1px solid #dee2e6 !important;
    border-radius: 8px !important;
    overflow: auto !important;
}

.tableHead {
    background-color: #F1F3F5;
    font-size: 14px;
    font-weight: 600;
    color: #495057;
}

.tableHead .mantine-Table-tr .mantine-Table-th p {
    display: inline-block;
    min-width: max-content;
}

#tableValue p {
    display: block;
    width: max-content;
}