.notificationContainer {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2) !important;
    margin-top: 0 !important;
    margin-bottom: 5px;
    font-family: Helvetica-light;
}

.navbar {
    width: max-content;
    height: 100vh;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #DFE0E1;
    padding: 20px 12px
}

.header {
    padding: 20px 15px 20px 10px;
    margin-bottom: calc(var(--mantine-spacing-md) * 1.5);
    border-bottom: 2px solid #495057;
}

.footer {
    position: relative;
    padding-top: var(--mantine-spacing-md);
    margin-top: var(--mantine-spacing-md);
    border-top: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.link {
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 16px;
    color: #C1C2C5;
    padding: 13px;
    border-radius: 4px;
    font-weight: 500;
    transition: 0.3s;

    &[data-active] {

        &,
        &:hover {
            background-color: #E9ECEF;
            color: black;

            .linkIcon {
                color: white;
            }
        }
    }
}

.link:hover {
    background-color: #E9ECEF;
    color: black;
}

.linkIcon {
    color: light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-2));
    margin-right: var(--mantine-spacing-sm);
    width: rem(25px);
    height: rem(25px);
}

/* create push notification */

/* .createPushNotificationContainer {
    height: auto;
    display: flex;
} */

.title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 24px;
    color: #212529;
}

.notificationType {
    font-size: 16px;
    font-weight: 600;
    color: #424242;
}

.notificationTypeContainer {
    display: flex;
    margin-top: 10px;
}

.application,
.email {
    width: 100px;
    height: 100px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #CED4DA;
    cursor: pointer;
}

.applicationTitle,
.emailTitle {
    font-size: 400;
    font-size: 14px;
    color: #5C5F66;
}

.createPushNotificationForm {
    margin-top: 20px;
}

.inputContainer:nth-child(2) {
    margin-top: 20px;
}

.inputContainer {
    margin-bottom: 20px;
}

.inputContainer textarea {
    border: 2px solid #CED4DA;
}

.inputContainer input {
    height: 40px;
    border: 2px solid #CED4DA;
}

.inputContainer .label {
    font-size: 14px;
    font-weight: 600;
    color: #424242;
}

.sendButtonContainer {
    display: flex;
    justify-content: end;
    margin-top: 20px;
}

/* verify email */

.verifyEmailContainer {
    border: 2px solid #cecece;
    border-radius: 8px;
}

.verifyEmailHeader {
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #212225;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.verifyEmailBody {
    padding: 24px 27px;
}

.verifyBodyTitle {
    font-size: 18px;
    font-weight: 800;
    color: #333333;
}

.verifyBodyMessage {
    font-size: 14px;
    font-weight: 600;
    color: #828282;
    width: 243px;
    margin: 22px 0;
}

.regards {
    font-size: 14px;
    font-weight: 600;
    color: #828282;
    width: 243px;
    margin: 22px 0;
}

.verifyEmailFooter {
    background-color: #E9F1FD;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: max-content;
    padding: 17px 27px;
}

.iconContainer {
    display: flex;
}

.iconContainer img {
    margin-right: 28px;
    margin-bottom: 10px;
    cursor: pointer;
}

/* schedule notification */

.scheduleNotificationCard {
    border: 2px solid #E3E3E3;
    border-radius: 8px;
    width: 100%;
    margin: 0 10px 0 0;
}

.scheduleNotificationCardTitle {
    font-family: "Helvetica" !important;
    font-size: 14px;
    font-weight: 600;
    margin-left: 6px;
}

.scheduleNotificationTitle {
    font-size: 24px;
    font-weight: 700;
    color: #212529;
}

.scheduleNotificationCardTitle2 {
    font-size: 14px;
    font-weight: 700;
    color: #212529;
    font-family: "Helvetica-bold" !important;
}

.scheduleNotificationCardDesc {
    font-family: "Helvetica" !important;
    font-size: 12px;
    color: #000000;
}

.scheduleNotification {
    width: 100%;
    height: 100%;
    padding: 24px 24px 24px 0;
}

.searchNotifications input {
    font-size: 14px;
    font-weight: 400;
    color: #343A40;
    border: 2px solid #CED4DA;
    border-radius: 6px;
    padding: 0 30px;
}

.searchNotifications input::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #343A40;
}

.recipient,
.type {
    border-top: 1px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
    display: flex;
    justify-content: space-between;
    padding: 6px 12px;
}