.inputSection {
    margin-top: 20px;
    margin-left: 23px;
    margin-right: 23px;
}

.inputContainer:nth-child(2) {
    margin-top: 20px;
}

.inputContainer {
    margin-bottom: 20px;
}

.inputContainer input {
    height: 40px;
    border: 2px solid #CED4DA;
}

.inputContainer .label {
    font-size: 16px;
    font-weight: 600;
    color: #373A40;
}

.statusContainer {
    /* height: 40px; */
    border: 2px solid #CED4DA;
    border-radius: 6px;
    width: 100%;
    padding: 10px;
}

.tabsWrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabContent {
    position: absolute;
    transition: transform 0.3s ease-in-out;
    width: 100%;
    top: 0;
    left: 0;
}

.tabContent.active {
    transform: translateX(0);
}

.tabContent.inactive {
    transform: translateX(100%);
}

#resendInvite {
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
}

.statusContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#resendInvite {
    font-weight: 600;
    font-size: 14px;
    color: #3354F4;
    margin-left: 8px;
}