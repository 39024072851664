.modalTitle {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px;
}

.modalDesc {
    font-size: 14px;
    font-weight: 400;
    color: #495057;
}

.modalContent {
    position: relative;
    text-align: center;
    width: 100%;
}

.uploadCSVBorder {
    border: 2px dashed #495057;
    height: 1000px;
    width: 100%;
}