.table {
    border: 1px solid #0000001A;
    border-radius: 15px;
}

.table .table-border-right {
    border-right: 1px solid #0000001A;
}

.color-box {
    border-radius: 6px;
}