@font-face {
    font-family: "Roboto-thin";
    src: url(./assets/fonts/Roboto/Roboto-Thin.ttf);
}

@font-face {
    font-family: "Roboto-light";
    src: url(./assets/fonts/Roboto/Roboto-Light.ttf);
}

@font-face {
    font-family: "Roboto-regular";
    src: url(./assets/fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
    font-family: "Roboto-bold";
    src: url(./assets/fonts/Roboto/Roboto-Bold.ttf);
}

@font-face {
    font-family: "Roboto-black";
    src: url(./assets/fonts/Roboto/Roboto-Black.ttf);
}

@font-face {
    font-family: "Roboto-medium";
    src: url(./assets/fonts/Roboto/Roboto-Medium.ttf);
}

/* Heveltica */

@font-face {
    font-family: "Helvetica";
    src: url(./assets/fonts/helvetica-255/Helvetica.ttf);
}

@font-face {
    font-family: "Helvetica-light";
    src: url(./assets/fonts/helvetica-255/helvetica-light-587ebe5a59211.ttf);
}

@font-face {
    font-family: "Helvetica-bold";
    src: url(./assets/fonts/helvetica-255/Helvetica-Bold.ttf);
}

/* Lexend */

@font-face {
    font-family: "Lexend-regular";
    src: url(./assets/fonts/Lexend/Lexend-Regular.ttf);
}

@font-face {
    font-family: "Lexend-light";
    src: url(./assets/fonts/Lexend/Lexend-Light.ttf);
}

@font-face {
    font-family: "Lexend-medium";
    src: url(./assets/fonts/Lexend/Lexend-Medium.ttf);
}

@font-face {
    font-family: "Lexend-bold";
    src: url(./assets/fonts/Lexend/Lexend-Bold.ttf);
}

/* Lexend */

@font-face {
    font-family: "SF-pro-display-regular";
    src: url(./assets/fonts/sf-pro-display/SFPRODISPLAYREGULAR.OTF);
}

@font-face {
    font-family: "SF-pro-display-medium";
    src: url(./assets/fonts/sf-pro-display/SFPRODISPLAYMEDIUM.OTF);
}

@font-face {
    font-family: "SF-pro-display-bold";
    src: url(./assets/fonts/sf-pro-display/SFPRODISPLAYBOLD.OTF);
}

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Manrope:wght@200..800&display=swap');

*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: "Roboto-regular" !important;
    overflow-x: hidden;
    background-color: #F8F9FA !important;
}

.mantine-Drawer-title {
    width: 100% !important;
}

.employeeTab .mantine-Tabs-tabLabel {
    display: flex;
    align-items: center;
}

.employeeTab p {
    margin-left: 10px;
    position: relative;
}

.mantine-Accordion-label {
    font-weight: 600 !important;
    font-size: 18px !important;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #0587e9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* accordion */

.mantine-Accordion-item button {
    padding: 0 !important;
}

.mantine-Accordion-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.mantine-Accordion-content p {
    font-size: 16px !important;
}

.mantine-Table-table {
    border: 2px solid #DEE2E6 !important;
    border-radius: 10px !important;
    border-collapse: 'separate' !important;
    border-spacing: '0' !important;
}

.m_b5489c3c {
    align-items: start !important;
}