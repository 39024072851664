.selected-organization {
    background-color: #E9ECEF;
}

.organization-row {
    min-width: max-content;
    width: 100%;
}

.selected-organization .selected-organization-rectangle {

    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #3354F4;

}

.light-button {
    border: 2px solid #A6A7AB !important;
    background-color: transparent !important;
    color: #A6A7AB !important;
}

.danger-button {
    border: 2px solid #C92A2A !important;
    background-color: #C92A2A !important;
    color: #ffffff !important;
}