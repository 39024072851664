.company-selection-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #495057;
}

.navlink {
    text-decoration: none;
    color: #C1C2C5;
}

.active {
    border-radius: 4px;
    background-color: #495057;
    text-decoration: none;
    color: #FFFFFF !important;
}

.icon-chevron {
    transition: transform 0.5s ease;
    /* Adjust duration as needed */
}

.icon-chevron-rotated {
    transition: transform 0.6s ease;
    transform: rotate(-180deg);
}