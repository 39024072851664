/* .formTitleContainer {
    margin-bottom: 3rem;
} */

.loginBtn {
    margin: 0 auto 0 !important;
    height: 48px !important;
    border-radius: 6px !important;
    font-size: 20px !important;
    font-weight: 600;
    line-height: 24px !important;
    background-color: rgb(51, 84, 244) !important;
}

.fileUploadContainer {
    display: flex;
}

.uploadDocumentImageButton {
    border: 1px solid #3354F4 !important;
    margin: 40px auto 0 !important;
    height: 48px !important;
    border-radius: 6px !important;
    font-size: 20px !important;
    font-weight: 600;
    line-height: 24px !important;
}
.uploadedFileActionButton {
    color: black !important;
    width:2.2rem;
    height:2.2rem;
    padding:0.4rem;
}

.uploadImg {
    background-color: #F1F3F5;
    border: 2px solid #3354F4;
    width: 80px;
    height: 80px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
    cursor: pointer;
}

.uploadImg2 {
    background-color: transparent;
    border: 2px solid #CED4DA;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
    cursor: pointer;
}

.collaborateTeam {
    width: 100%;
    height: 249px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
}


.uploadDocument {
    border: dashed #DEE2E6;
    background-color: #FFF;
    width: 100%;
    height: 249px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-right: 20px; */
    cursor: pointer;
}

.removeImage {
    border: 1px solid #ADB5BD;
    width: 38px;
    height: 38px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    cursor: pointer;
}
