.radio-choice-wrapper {
    border-radius: 6px;

    &[data-checked] {
        border-color: var(--mantine-primary-color-filled);
        background-color: var(--mantine-primary-color-light);
    }

}

.radio-choice {
    border-radius: 6px;
    color: var(--mantine-primary-color-filled);
    background-color: white;
}