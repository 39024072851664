
#tableWrapper {
    border-radius: '8px';
    border: '1px solid #ddd' ;
    overflow: 'hidden';
}

.tableHead {
    background-color: #F1F3F5;
    font-size: 14px;
    font-weight: 600;
    color: #495057;
}

#tableHeader p {
    display: block;
    width: max-content;
}

#tableValue p {
    display: block;
    width: max-content;
}

.tableData {
    font-size: 14px;
    font-weight: 400;
    color: black;
    border: 1px solid #DEE2E6;
    cursor: pointer;
}

.tableAction {
    color: #868E96;
    font-size: 16px;
    font-weight: 500;
    /* margin-left: 10px; */
}

.tableAction:hover {
    color: #3354F4;
    cursor: pointer;
}

.hoverRow:hover {
    background-color: #EDF2FF !important; /* Set your desired hover color */
    cursor: pointer;

  }