.input input {
    height: 32px;
    font-size: 14px;
    font-weight: 400;
    color: #343A40;
    border: 1px solid #CED4DA;
    border-radius: 6px;
    padding: 0 30px;
}

.input input::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #343A40;
}

#filterButton {
    /* width: 80px; */
    background-color: #E9ECEF;
    border: 2px solid #ADB5BD;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #343A40;
    margin-left: 10px;
}

#export {
    /* width: 80px; */
    background-color: #E7F5FF;
    border: 1px solid #3354F4;
    border-radius: 10px;
    color: #3354F4;
    /* margin-left: 10px; */
}

#export2 {
    /* width: 80px; */
    background-color: #E7F5FF;
    border: 1px solid #3354F4;
    border-radius: 10px;
    color: #3354F4;
    /* margin-left: 10px; */
}

#export:hover {
    background-color: #3354F4;
    color: #E7F5FF;
}

#upload,
#addNew {
    /* width: 80px; */
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    /* margin-left: 10px; */
}

.employeeTable {
    margin: 20px 0;
    position: relative;
    overflow-x: auto;
    height: calc(100vh - 223px);
}

.employeeTable table {
    position: absolute;
    top: 0;
}

.invited {
    width: 100%;
    height: 28px;
    border-radius: 4px;
    background-color: #FD7E141F;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: #FD7E14;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
}

.error {
    width: 100%;
    height: 28px;
    border-radius: 4px;
    background: rgba(224, 49, 49, 0.12);
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: #E03131;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
}

.active {
    width: 100%;
    height: 28px;
    border-radius: 4px;
    background: rgba(64, 192, 87, 0.12);
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: #37B24D;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
}

.paid {
    width: 100%;
    height: 28px;
    border-radius: 4px;
    background-color: #40C0571F;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: #37B24D;
    display: flex;
    justify-content: center;
    align-items: center;
}

.resendInvite {
    width: 65px;
    height: 28px;
    border-radius: 4px;
    background-color: #FD7E141F;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #FD7E14;
    display: flex;
    justify-content: center;
    align-items: center;
}

.drawerTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px 23px;
}

.border {
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin-top: 5px;
}