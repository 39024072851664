.uploadFileSection {
    border: 1px dashed #DEE2E6;
    border-radius: 8px;
    color: #5C5F66;
    font-size: 14px;
    font-weight: 400;
}

.uploadFileSection .dot {
    display: "inline-block";
    margin: "4px 5px";
    width: "4px";
    height: "4px";
    border-radius: "100%";
    background-color: "#5C5F66"
}
.mantine-Modal-title{
    font-weight: 700;
    font-size: 16px;
}