.slider-container {
    background-color: black;
    /* border-radius: 20px; */

}

.slider-container .slick-slider {
    /* border-radius: 20px; */
    width: 100%;

}

/* .slider-container .slick-slider .slick-list {
    border-radius: 20px;
} */

.slider-container .slick-slider .slick-dots {
    bottom: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-container .slick-slider .slick-dots li {
    width: 20px;
    height: 25px;
    position: relative;
    margin: 0 10px;
}

.slider-container .slick-slider .slick-dots li button {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    background-color: gray;
    transition: all 0.3s ease;
    /* border-radius: 2px; */
    clip-path: polygon(0% 0%, 30% 0%, 100% 50%, 30% 100%, 0% 100%);
    &::before {
        content: none;
    }
}

.slider-container .slick-slider .slick-dots .slick-active {
    width: 20px;
    height: 25px;
    position: relative;
}

.slider-container .slick-slider .slick-dots .slick-active button {
    background-color: red;
    /* border-radius: 2px; */
    width: 20px;
    height: 25px;
    clip-path: polygon(0% 0%, 30% 0%, 100% 50%, 30% 100%, 0% 100%);
}